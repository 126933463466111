$defaultIconPrimary: #3699ff;
$defaultFaEdit: #3699ff;
$defaultFaClone: #3699ff;
$defaultFaDis: #b5b5c3;
$defaultbrancdColor: #1a1a27;
$defaultActiveRingColor: #69b3ff;
$defaultAsideMenuTextColor: #3699ff;
$infoBackColor: #e1f0ff;
$defaultFaEye: #3699ff;
$defaultTegColor: #3699ff;

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

.marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.marker-green {
  background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}

/* ckeditor5-highlight/theme/highlight.css */
.marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

i.fa.fa-clone {
  color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #ffffff !important;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.btn.btn-primary {
  color: #ffffff;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.brand-dark .brand {
  background-color: var(--default-brand, $defaultbrancdColor) !important;
  box-shadow: none;
}

.nav .show > .nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  color: var(
    --default-icon-primary,
    $defaultIconPrimary
  ) !important; // need to higlight
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 40px !important;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding: 0 !important;
  background-color: #ffffff;
}

.content {
  padding: 0 !important;
}

.addButton {
  position: absolute;
  right: 50px;
  top: 25px;
}

.card.card-custom {
  box-shadow: 0px 0px 0px 0px !important;
  height: 100%;
}

// Onboarding signup information request radio
.cm-inforeqradio {
  padding: 10px;
  border: 1px solid #80808030;
  border-radius: 2px;

  .custom-control-input {
    width: 100%;
  }

  .custom-control-label::before {
    top: 0;
    left: 25.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .custom-control-label::after {
    top: 0;
    left: 25.5rem;
    width: 0;
    height: 0;
  }
}

.cm-signup-info-list,
.cm-onboard-steps-list,
.cm-tc-step-detail-list {
  padding: 0 10px;
  border: 1px solid #80808030;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Onboarding signup information request radio

//Subscription
.cm-subscription-plan-list {
  padding: 0 10px;
  border: 1px solid #80808030;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//Subscription

// Set ck editor hight
.ck .ck-content {
  height: 160px;
}

// Modal close button css (x)
.modal .modal-header .close span {
  display: block;
}

.react-datepicker-wrapper {
  width: 100%;
}

// set Area Of Operation tab table design (Serivce Provider)
.cm-header {
  text-transform: uppercase;
  color: #4a89dc;
}

.cm-area-of-operatiion .cm-empty-msg {
  color: #626262;
}

// delete button design
.cm-btn-delete {
  width: 45px;
}

//Switch Account
.cm-switch-account {
  cursor: pointer;

  .cm-link {
    color: white;
  }
}

.cm-color-goldenrod {
  color: goldenrod;
}

.cm-table-width {
  width: 100%;
}

// set opacity to start tabindex in image upload button
.cm-input-upload-h {
  max-height: 0;
  max-width: 0;
  opacity: 0;
}

// Set design for tabs
.nav.nav-tabs .nav-item {
  padding: 9px 12px;
}

// set Service Provider additional business dd message design
.cm-business-multiSelect .item-renderer span {
  width: 100%;
}

.cm-table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
.cm-table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f6f9;
}

.pinned {
  position: relative;
  border-collapse: collapse;
}
.pinned > thead {
  position: relative;
}


.pinned > thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #ffffff;
}

.tableContainer {
  position: relative;
}
.pinBtn {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotateZ(-45deg);
  padding: 10px ;
  z-index: 99999;
  cursor: pointer;
}

.icon-i {
  font-size: 15px;
}

@keyframes rotateOutward {
  0% {
    transform: rotateZ(-45deg) scale(1);
  }
  50% {
    transform: rotateZ(-45deg) scale(1.2);
    top: -35px;
  }
  100% {
    transform: rotateZ(0deg) scale(1);
    top: -35px;
  }
}

@keyframes rotateInward {
  0% {
    transform: rotateZ(0deg) scale(1);
    top: -35px;
  }
  50% {
    transform: rotateZ(-45deg) scale(1.2);
    top: 0px;
  }
  100% {
    transform: rotateZ(-45deg) scale(1);
    top: 0px;
  }
}

.activePin {
  animation: rotateInward forwards 0.5s ease-in-out;
}

.inActivePin {
  animation: rotateOutward forwards 0.5s ease-in-out;
}

.cm-btn-sm-w-i {
  padding: 3px 6px;
}

.cm-btn-sm-w-i > i {
  font-size: 12px !important;
}

//To stick cancel and save button at bottom
.cm-form-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 30px;
}

// note added by ui
.cm-note-added-by {
  font-size: 12px;
  font-style: italic;
  color: gray;
}

/* Additional business drop-down */
.cm-business-multiSelect .select-panel .options {
  max-height: 230px !important;
}

/* Additional business drop-down */

/*Page content css - start*/
.cm-page-content {
  padding-bottom: 20px !important;
}

/*Page content css - end*/

/* Form Button Fixed to Bottom */
.cm-fixed-btn-wrapper {
  position: fixed;
  bottom: 44px;
  right: 0;
  background: #fff;
  // z-index: 10;
  margin-bottom: -3.25rem !important;
  padding: 10px 1rem;
}

/* Footer - Start */
.cm-scrolltop {
  position: unset !important;
  right: unset !important;
  bottom: unset !important;
  height: 25px !important;
  width: 25px !important;
}

.cm-scrolltop > i {
  font-size: 14px;
}

.cm-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 265px);
  padding: 8px 0;
  margin: 0;
  border-top: 1px solid #ebedf3;
  min-height: 44px;
  justify-content: center;
  z-index: 10;
}

.aside-minimize .cm-footer {
  width: calc(100% - 70px);
}

/* Footer - End */

/* Custom Scrollbar - Start */
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}

::-webkit-scrollbar-thumb:hover {
  background: #777777;
}

/* Custom Scrollbar - End */

.card.card-custom > .card-body {
  padding: 1rem !important;
}

.card.card-custom > .cm-pb-on-fix-btn {
  padding-bottom: 1rem !important;
}

.aside-minimize:not(.aside-minimize-hover) .aside .cm-switch-account {
  display: none;
}

.member-popup-scroll {
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cm-header-content-name-wrapper {
  max-width: 30ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

::placeholder {
  font-size: 12px;
}

.cm-checkbox-big {
  transform: scale(1.5);
}

.cm-action-icons i {
  font-size: 1rem;
}

.bg-light-primary {
  background-color: var(--default-info-color, $infoBackColor) !important;
}

.menu-arrow {
  color: var(--default-asideText, $defaultAsideMenuTextColor) !important;
}

.menu-text {
  color: var(--default-asideText, $defaultAsideMenuTextColor) !important;
  &:hover {
      color: #ffffff !important;
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-text {
  color: #ffffff !important;
}

.menu-item {
  &:hover {
    .menu-arrow {
      color: #ffffff !important;
    }
  }
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-bullet.menu-bullet-dot > span {
  background-color: #ffffff !important;
}
.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-bullet.menu-bullet-dot > span {
  background-color: var(--default-asideText, $defaultAsideMenuTextColor) !important;
}

.react-datepicker__day--selected {
  background-color: var(--default-datePicker-theme, $defaultFaEdit) !important;
}

.form-control:focus {
  border-color: var(--default-activeRingColor, $defaultActiveRingColor) !important;
}

.selection-cell .checkbox > input:checked ~ span {
   background-color: var(--default-datePicker-theme, $defaultFaEdit) !important;
}

.checkbox > input:checked ~ span {
  background-color: var(--default-datePicker-theme, $defaultFaEdit) !important;
}

.ck.ck-content.ck-editor__editable.ck-focused {
  border-color: var(--default-activeRingColor, $defaultActiveRingColor) !important; 
}

.react-bootstrap-table .table.table-head-custom thead tr th.sortable-active, .react-bootstrap-table .table.table-head-custom thead tr td.sortable-active {
  color: var(--default-activeRingColor, $defaultActiveRingColor) !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
  background-color: var(--default-datePicker-theme, $defaultFaEdit) !important;
}

.svg-icon.svg-icon-primary svg g [fill]{
  fill: var(--default-activeRingColor, $defaultActiveRingColor) !important;
}

.react-tagsinput--focused {
  border-color: var(--default-activeRingColor, $defaultActiveRingColor) !important;
}

.react-tagsinput-tag{
  background-color: var(--default-teg-color, $defaultTegColor) !important;
  border: var(--default-info-color, $defaultActiveRingColor) !important;
  color: var(--default-info-color, $defaultActiveRingColor) !important;
}

.rmsc .dropdown-container:focus-within{
  border-color: var(--default-activeRingColor, $defaultActiveRingColor) !important;
  box-shadow: var(--default-activeRingColor, $defaultActiveRingColor) !important;
}

.rmsc .dropdown-container:focus{
  border-color: var(--default-activeRingColor, $defaultActiveRingColor) !important;
  box-shadow: var(--default-activeRingColor, $defaultActiveRingColor) !important;
}

.cm-action-icons {
  .fa-trash {
    color: red;
  }

  .fa-edit {
    color: var(--default-fa-edit, $defaultFaEdit);
  }

  .fa-eye {
    color: var(--default-fa-eye, $defaultFaEye);
  }

  d .fa-clone {
    color: var(--default-fa-clone, $defaultFaClone);
  }

  .dis {
    color: var(--default-fa-dis, $defaultFaDis);
  }
}

.cm-disabled {
  .dropdown-container {
    background-color: #f3f6f9 !important;
  }
}

.cm-dropdown-position-top .dropdown-content {
  top: auto !important;
  bottom: 100%;
}

.cm-table-fixed-grid0 {
  height: calc(100vh - 265px);
}

.cm-table-fixed-grid1 {
  height: calc(100vh - 280px);
}

.cm-table-fixed-grid2 {
  height: calc(100vh - 322px);
}

.cm-table-fixed-grid-max {
  max-height: calc(100vh - 280px);
}
.notification-exit {
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.cm-dragndrop-wrapper {
  height: calc(100vh - 242px);
  overflow-y: auto;
  padding: 12.5px;
}

.cm-btn-sm-x {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  max-width: 10.33333%;
}

.cm-icon-primary {
  color: var(--default-icon-primary, $defaultIconPrimary) !important; //#3699ff;
}

.custom-radio
  .custom-control-input:checked
  ~ .custom-control-label.success::before {
  background-color: #93c01f;
  border-color: #93c01f;
}

.custom-radio,
.custom-checkbox {
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label.success {
          &::before {
            background-color: #93c01f;
            border-color: #93c01f;
          }
        }

        .custom-control-label.error {
          &::before {
            background-color: #d0021b;
            border-color: #d0021b;
          }
        }
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 150px !important;
}

.emailTemplateEditor .ck-editor__editable_inline {
  min-height: 430px !important;
}

.ck-content .table {
  width: 100% !important;
}

.ck-content .table .p {
  margin: 0 !important;
}

.cm-bg-error {
  background-color: #d0021b;
}

.cm-bg-success {
  background-color: #93c01f;
}

.aside-minimize .cm-logo {
  display: none;
}

.aside-minimize.aside-minimize-hover .cm-logo {
  display: initial;
}

.cm-training-wrapper {
  height: calc(100vh - 126px);
  overflow-y: auto;
}

.popup-view {
  background-color: var(--default-aside-theme, $defaultbrancdColor) !important;
}

.ck-content i {
  font-size: 1em;
  color: inherit;
}

.cm-training-wrapper i {
  font-size: 1em;
  color: inherit;
}

.cm-result-page {
  overflow-y: auto;
  max-height: calc(100vh - 188px);
  padding: 0 15px 15px;
}

.cm-training-wrapper .media iframe {
  width: calc(100vw - 30px) !important;
  min-height: 55vw;
}

.ck-media__wrapper div {
  padding-bottom: 20% !important;
}

.emailTemplateFooter {
  flex-direction: row-reverse;
}

.btn.btn-emailtemp {
  background-color: rgb(43, 81, 92) !important;
  color: #fff !important;
  border-color: rgb(43, 81, 92) !important;
}

.leaderboard {
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
}

.template-tag-list {
  max-height: 470px;
  overflow-y: scroll;
  border: 2px solid grey;
  border-radius: 0px;
}

.clubReadyIcon {
  height: 25px !important;
  width: 25px !important;
}

.inputText-label-Icon {
  height: 20px !important;
  width: 20px !important;
  margin-left: 4px;
}

.label.label-status {
  height: 50px;
}

.brand-dark .brand .cm-logo + .btn .svg-icon svg g [fill],
.brand-dark .brand .cm-logo + .btn.active .svg-icon svg g [fill] {
  fill: #ffffff;
}

.label-light-brown {
  color: #523526;
  background-color: #dfb29c;
}

.green-checkbox {
  background-color: #198754;
  appearance: none;
  height: 13px;
  width: 13px;
}

.yellow-checkbox {
  background-color: #ffc107;
  appearance: none;
  height: 13px;
  width: 13px;
}

.green-label,
.yellow-label {
  position: relative !important;
}

.green-label::after,
.yellow-label::after {
  content: "\f00c";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.singleSelect .rmsc .item-renderer input {
  margin: 0 5px 0 0;
  display: none;
}

.listofbusiness {
  max-height: calc(100vh - 310px);
  overflow-y: auto;
  overflow-x: clip;
  list-style-type: none;
}

.addBusinessBtn{
  padding-top: 230px;
    max-width: 6.33333%;
    margin-left: 2px;
    margin-right: -2px;
}

.cm-business-multiSelect-addition .item-renderer span {
  width: 50%;
}
.cm-business-multiSelect-addition .select-panel .options {
  max-height: calc(100vh - 354px);
}

.onAddArrow{
  font-size: 2rem;
    color: #5c9bfe;
}

.listView ul li:nth-child(even) {
  background-color: rgb(220, 220, 220);
}

.flex-container{
  max-width: 47%;
  width: 50%;
  height: 100%;
}

.flex-container-button{
  padding-top: 20%;
  margin: 2%;
}

.flex-container .clear-selected-button{
  display: none !important;
}

.deleteLogo{
  font-size: 1.3rem;
    color: #f02424;
}

/*search bar css start*/
.cm-search-container {
  display: flex;
  align-items: center;

  .cm-close{
    .cm-search-icon {
      color: #ffffff;
      cursor: pointer;
    }
  }

  .cm-search-input {
    border-radius: 35px;
    padding: 10px;
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    &.active {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      background-color: #181c32;
      width: 100%;
      height: 4.5rem;
    }

    .cm-close{
      .cm-search-icon {
        font-size: 1.8rem;
      }
    }

    .cm-search-input {
      width: 100%;
    }
  }
}
/*search bar css end*/

i.fa.fa-clone.black {
  color: #181c32 !important;
}
.cm-action-icons .black{
  color: #181c32 !important;
}

.upcoming-consults-filter-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.state-button {
  background-color: #F3F6F9;
  border: 1px solid #F3F6F9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;

  span:first-child {
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
    color: #7E8299;
  }

  span:last-child {
    font-size: 22px;
    font-weight: 700;
    line-height: 26.61px;
    color: #3F4254;
  }

  &:hover {
    background-color: #F3F6F9;
    border-color: #284A69;
  }

  &:focus {
    outline: none;
    border-color: #284A69;
  }
  &:active {
    background-color: #d6e0eb;
  }

  &.active {
    background-color: #fff;
    border-color: #284A69;
  }

  &.primary {
    background-color: #6896C1;
    color: #fff;
  }
}

.refresh-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  min-width: 100px;
  max-width: fit-content;
  color: #3F4254;
}

.s2-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: #7E8299;
}

.refresh-loader {
  transition: all 0.3s ease;
  animation: refresh 3s linear infinite forwards;
}

@keyframes refresh {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(700deg);
  }
  20% {
    transform: rotate(1400deg);
  }
  30% {
    transform: rotate(1800deg);
  }
  40% {
    transform: rotate(2200deg);
  }
  50% {
    transform: rotate(2600deg);
  }
  60% {
    transform: rotate(3000deg);
  }
  70% {
    transform: rotate(3200deg);
  }
  80% {
    transform: rotate(3400deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}

.cm-badge {
  width: min-content;
  height: 27px;
  margin: 2px;
  padding: 6px 16px 6px 16px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  line-height: 15.4px;

  &.success {
    color: #3B6131;
    background-color: #CBF3C0;
  }

  &.warning {
    color: #825F08;
    background-color: #FFE6A8;
  }

  &.danger {
    color: #BD3D44;
    background-color: #F9D6D8;
  }

  &.info {
    color: #3F4254;
    background-color: #F3F6F9;
  }
}

.cm-success-bg {
  background-color: #0E8345;
}
.cm-warning-bg {
  background-color: #FFB749;
}
.cm-danger-bg {
  background-color: #DC362E;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  outline: 1px solid #F7F7F7;
  z-index: 10;
  display: block;
  flex-shrink: 0;
  
  &.absolute {
    position: absolute;
    bottom: 1px;
    right: 1px;
  }
}

.cm-sub-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7E8299;
}

.cm-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #3F4254;
}

.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}

.gap-10{
  gap: 10px;
}

.gap-12{
  gap: 12px;
}

.gap-16{
  gap: 16px;
}

.pill {
  background-color: #F3F6F9;
  height: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  outline: 1px solid #fff;
  &> span {
    color: #575757;
  }

  &.success {
    outline: none;
    background-color: #20AE31;
    color: #fff;
    &> span {
      font-weight: 700;
      color: #fff;
    }
    &:hover {
      text-decoration-line: underline;
    }
  }
}

.cm-flex-1 {	
  flex: 1 1 0 !important;	
}


.cm-btn-color {
  color: #FFFFFF !important;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  min-width: 90px;
  border-radius: 4px !important;
}

.cm-btn-outline-primary {
  color: var(--default-icon-primary, $defaultIconPrimary) !important;
  background-color: transparent !important;
  border-color: var(--default-icon-primary, $defaultIconPrimary) !important;
  min-width: 90px;
}

.cm-btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: var(--default-icon-primary, $defaultIconPrimary) !important;
}

.cm-promo-color {
  color: var(--default-icon-primary, $defaultIconPrimary) !important;
  font-weight: 900;
  font-size: 15px;
  text-decoration: underline;
}

.flash-card{
  width: 100%;
  height:100%;
  min-height: 134px;
  padding: 20px;
  color: #3F4254; 
  background-color: #F3F6F9;
  border-radius: 8px;
}

.flash-card-bg{
  background-color: #F3F6F9;
}

.font-color-green{
  color: #0E8345
}

.font-color-red{
  color: #DC362E
}

.flash-card span:first-of-type {
  font-weight: 500;
  font-size: 13px;
}

.flash-card.currency {
 font-weight: 700;
 font-size: 23px;
}

.list-item{
  padding-top: 12px;
  padding-bottom: 12px;
  color: #3F4254;
  justify-content: space-between;
}

.date-picker{
  color: #6E6E6E;
  font-weight: 400;
  font-size: 13px;
}
.react-datepicker__input-container:first-of-type input{
  color: #282828;
  font-weight: 500;
  font-size: 12px;
}

.flash-card-container{
  width: calc(20% - 10px);
}

.booking-section-container{
  width: 380px;
  margin-top: 10px;
}
.order-funnel{
  flex : 1;
}

.card-funnel{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.funnel-chart {
  width: 845px;
  height: 150px;
}

.booking-order-section{
  display: flex;
  gap: 10px;
}
@media (max-width: 768px) {
  .flash-card-container{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .booking-section-container{
    width: 100% !important;
  }
  .order-funnel{
    width: 100% !important;
  }
  .funnel-chart{
    width: 100% !important;
  }
  .booking-order-section{
    flex-wrap: wrap !important;
  }
}

